import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _18fc4100 = () => interopDefault(import('../shell/pages/about.vue' /* webpackChunkName: "shell/pages/about" */))
const _531ccb63 = () => interopDefault(import('../shell/pages/account/index.vue' /* webpackChunkName: "shell/pages/account/index" */))
const _08c81659 = () => interopDefault(import('../shell/pages/c/index.vue' /* webpackChunkName: "shell/pages/c/index" */))
const _6b9d5e99 = () => interopDefault(import('../shell/pages/clusters/index.vue' /* webpackChunkName: "shell/pages/clusters/index" */))
const _35f50a44 = () => interopDefault(import('../shell/pages/diagnostic.vue' /* webpackChunkName: "shell/pages/diagnostic" */))
const _2edb9252 = () => interopDefault(import('../shell/pages/fail-whale.vue' /* webpackChunkName: "shell/pages/fail-whale" */))
const _38eb0f3c = () => interopDefault(import('../shell/pages/home.vue' /* webpackChunkName: "shell/pages/home" */))
const _1c6c76a3 = () => interopDefault(import('../shell/pages/prefs.vue' /* webpackChunkName: "shell/pages/prefs" */))
const _29446e2d = () => interopDefault(import('../shell/pages/safeMode.vue' /* webpackChunkName: "shell/pages/safeMode" */))
const _55f2c2f6 = () => interopDefault(import('../shell/pages/support/index.vue' /* webpackChunkName: "shell/pages/support/index" */))
const _5ba84766 = () => interopDefault(import('../shell/pages/account/create-key.vue' /* webpackChunkName: "shell/pages/account/create-key" */))
const _87cdc542 = () => interopDefault(import('../shell/pages/auth/login.vue' /* webpackChunkName: "shell/pages/auth/login" */))
const _141b9704 = () => interopDefault(import('../shell/pages/auth/logout.vue' /* webpackChunkName: "shell/pages/auth/logout" */))
const _7f73be73 = () => interopDefault(import('../shell/pages/auth/setup.vue' /* webpackChunkName: "shell/pages/auth/setup" */))
const _177a0b1a = () => interopDefault(import('../shell/pages/auth/verify.vue' /* webpackChunkName: "shell/pages/auth/verify" */))
const _635af89e = () => interopDefault(import('../shell/pages/docs/toc.js' /* webpackChunkName: "shell/pages/docs/toc" */))
const _1dc831e1 = () => interopDefault(import('../shell/pages/rio/mesh.vue' /* webpackChunkName: "shell/pages/rio/mesh" */))
const _1fd989f2 = () => interopDefault(import('../shell/pages/c/_cluster/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/index" */))
const _446be7c0 = () => interopDefault(import('../shell/pages/docs/_doc.vue' /* webpackChunkName: "shell/pages/docs/_doc" */))
const _1868e6e0 = () => interopDefault(import('../shell/pages/c/_cluster/apps/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/apps/index" */))
const _554d9446 = () => interopDefault(import('../shell/pages/c/_cluster/auth/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/index" */))
const _915c01c0 = () => interopDefault(import('../shell/pages/c/_cluster/backup/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/backup/index" */))
const _4ea7e476 = () => interopDefault(import('../shell/pages/c/_cluster/cis/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/cis/index" */))
const _0a2c0a07 = () => interopDefault(import('../shell/pages/c/_cluster/ecm/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/ecm/index" */))
const _c52cf6c6 = () => interopDefault(import('../shell/pages/c/_cluster/explorer/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/index" */))
const _9594ec74 = () => interopDefault(import('../shell/pages/c/_cluster/fleet/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/fleet/index" */))
const _a46914ca = () => interopDefault(import('../shell/pages/c/_cluster/gatekeeper/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/gatekeeper/index" */))
const _1fd4c528 = () => interopDefault(import('../shell/pages/c/_cluster/istio/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/istio/index" */))
const _53503f32 = () => interopDefault(import('../shell/pages/c/_cluster/legacy/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/index" */))
const _63727357 = () => interopDefault(import('../shell/pages/c/_cluster/logging/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/logging/index" */))
const _8a4bcb46 = () => interopDefault(import('../shell/pages/c/_cluster/longhorn/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/longhorn/index" */))
const _6903a3b6 = () => interopDefault(import('../shell/pages/c/_cluster/manager/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/index" */))
const _cd527234 = () => interopDefault(import('../shell/pages/c/_cluster/mcapps/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/mcapps/index" */))
const _44f024a6 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/index" */))
const _9b6e92ce = () => interopDefault(import('../shell/pages/c/_cluster/neuvector/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/neuvector/index" */))
const _54f45b81 = () => interopDefault(import('../shell/pages/c/_cluster/settings/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/index" */))
const _73f47b04 = () => interopDefault(import('../shell/pages/c/_cluster/uiplugins/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/uiplugins/index" */))
const _3ab5644a = () => interopDefault(import('../shell/pages/c/_cluster/apps/charts/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/apps/charts/index" */))
const _15f082fe = () => interopDefault(import('../shell/pages/c/_cluster/auth/config/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/config/index" */))
const _ba76eb18 = () => interopDefault(import('../shell/pages/c/_cluster/auth/roles/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/roles/index" */))
const _267d536c = () => interopDefault(import('../shell/pages/c/_cluster/explorer/ConfigBadge.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/ConfigBadge" */))
const _71f1abc0 = () => interopDefault(import('../shell/pages/c/_cluster/explorer/EventsTable.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/EventsTable" */))
const _1dbabeb2 = () => interopDefault(import('../shell/pages/c/_cluster/explorer/explorer-utils.js' /* webpackChunkName: "shell/pages/c/_cluster/explorer/explorer-utils" */))
const _9cf3e9ae = () => interopDefault(import('../shell/pages/c/_cluster/explorer/tools/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/tools/index" */))
const _075ece87 = () => interopDefault(import('../shell/pages/c/_cluster/fleet/GitRepoGraphConfig.js' /* webpackChunkName: "shell/pages/c/_cluster/fleet/GitRepoGraphConfig" */))
const _2318d382 = () => interopDefault(import('../shell/pages/c/_cluster/gatekeeper/constraints/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/gatekeeper/constraints/index" */))
const _57ac57f1 = () => interopDefault(import('../shell/pages/c/_cluster/legacy/project/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/project/index" */))
const _244eaa42 = () => interopDefault(import('../shell/pages/c/_cluster/manager/cloudCredential/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/cloudCredential/index" */))
const _74c080f2 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/alertmanagerconfig/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/alertmanagerconfig/index" */))
const _9b397b9e = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/monitor/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/monitor/index" */))
const _005e8e92 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/route-receiver/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/route-receiver/index" */))
const _678fad36 = () => interopDefault(import('../shell/pages/c/_cluster/settings/banners.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/banners" */))
const _56291bb6 = () => interopDefault(import('../shell/pages/c/_cluster/settings/brand.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/brand" */))
const _00c29606 = () => interopDefault(import('../shell/pages/c/_cluster/settings/DefaultLinksEditor.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/DefaultLinksEditor" */))
const _2550b6e8 = () => interopDefault(import('../shell/pages/c/_cluster/settings/links.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/links" */))
const _7b8412c2 = () => interopDefault(import('../shell/pages/c/_cluster/settings/performance.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/performance" */))
const _3874b7f7 = () => interopDefault(import('../shell/pages/c/_cluster/uiplugins/DeveloperInstallDialog.vue' /* webpackChunkName: "shell/pages/c/_cluster/uiplugins/DeveloperInstallDialog" */))
const _7aed2655 = () => interopDefault(import('../shell/pages/c/_cluster/uiplugins/InstallDialog.vue' /* webpackChunkName: "shell/pages/c/_cluster/uiplugins/InstallDialog" */))
const _78822d15 = () => interopDefault(import('../shell/pages/c/_cluster/uiplugins/PluginInfoPanel.vue' /* webpackChunkName: "shell/pages/c/_cluster/uiplugins/PluginInfoPanel" */))
const _27cff8fa = () => interopDefault(import('../shell/pages/c/_cluster/uiplugins/RemoveUIPlugins.vue' /* webpackChunkName: "shell/pages/c/_cluster/uiplugins/RemoveUIPlugins" */))
const _22e38726 = () => interopDefault(import('../shell/pages/c/_cluster/uiplugins/SetupUIPlugins.vue' /* webpackChunkName: "shell/pages/c/_cluster/uiplugins/SetupUIPlugins" */))
const _1a5d465c = () => interopDefault(import('../shell/pages/c/_cluster/uiplugins/UninstallDialog.vue' /* webpackChunkName: "shell/pages/c/_cluster/uiplugins/UninstallDialog" */))
const _6dd0a554 = () => interopDefault(import('../shell/pages/c/_cluster/apps/charts/chart.vue' /* webpackChunkName: "shell/pages/c/_cluster/apps/charts/chart" */))
const _5cdc46da = () => interopDefault(import('../shell/pages/c/_cluster/apps/charts/install.vue' /* webpackChunkName: "shell/pages/c/_cluster/apps/charts/install" */))
const _9ed144b6 = () => interopDefault(import('../shell/pages/c/_cluster/apps/charts/install.helpers.js' /* webpackChunkName: "shell/pages/c/_cluster/apps/charts/install.helpers" */))
const _54f2d50c = () => interopDefault(import('../shell/pages/c/_cluster/auth/group.principal/assign-edit.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/group.principal/assign-edit" */))
const _0ecf0630 = () => interopDefault(import('../shell/pages/c/_cluster/legacy/project/pipelines.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/project/pipelines" */))
const _e58a2e88 = () => interopDefault(import('../shell/pages/c/_cluster/manager/cloudCredential/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/cloudCredential/create" */))
const _7c8d62a6 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/monitor/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/monitor/create" */))
const _33278e6c = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/route-receiver/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/route-receiver/create" */))
const _a265580c = () => interopDefault(import('../shell/pages/c/_cluster/explorer/tools/pages/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/tools/pages/_page" */))
const _3e7525ae = () => interopDefault(import('../shell/pages/c/_cluster/auth/config/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/config/_id" */))
const _dc247a90 = () => interopDefault(import('../shell/pages/c/_cluster/legacy/pages/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/pages/_page" */))
const _7f24c6e6 = () => interopDefault(import('../shell/pages/c/_cluster/legacy/project/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/project/_page" */))
const _f6b355ac = () => interopDefault(import('../shell/pages/c/_cluster/manager/cloudCredential/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/cloudCredential/_id" */))
const _154649f6 = () => interopDefault(import('../shell/pages/c/_cluster/manager/pages/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/pages/_page" */))
const _9440e412 = () => interopDefault(import('../shell/pages/c/_cluster/mcapps/pages/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/mcapps/pages/_page" */))
const _f6c2a820 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/alertmanagerconfig/_alertmanagerconfigid/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/alertmanagerconfig/_alertmanagerconfigid/index" */))
const _05030d7a = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/route-receiver/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/route-receiver/_id" */))
const _a9b0ada8 = () => interopDefault(import('../shell/pages/c/_cluster/auth/roles/_resource/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/roles/_resource/create" */))
const _41fe647e = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/alertmanagerconfig/_alertmanagerconfigid/receiver.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/alertmanagerconfig/_alertmanagerconfigid/receiver" */))
const _70b6eaba = () => interopDefault(import('../shell/pages/c/_cluster/auth/roles/_resource/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/roles/_resource/_id" */))
const _33745838 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/monitor/_namespace/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/monitor/_namespace/_id" */))
const _b27ea8a4 = () => interopDefault(import('../shell/pages/c/_cluster/navlinks/_group.vue' /* webpackChunkName: "shell/pages/c/_cluster/navlinks/_group" */))
const _7eccc6ce = () => interopDefault(import('../shell/pages/c/_cluster/_product/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/index" */))
const _24dce558 = () => interopDefault(import('../shell/pages/c/_cluster/_product/members/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/members/index" */))
const _22acf1a8 = () => interopDefault(import('../shell/pages/c/_cluster/_product/namespaces.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/namespaces" */))
const _294b6626 = () => interopDefault(import('../shell/pages/c/_cluster/_product/projectsnamespaces.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/projectsnamespaces" */))
const _9a37a0a8 = () => interopDefault(import('../shell/pages/c/_cluster/_product/_resource/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/_resource/index" */))
const _5d53dedc = () => interopDefault(import('../shell/pages/c/_cluster/_product/_resource/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/_resource/create" */))
const _225ba5d8 = () => interopDefault(import('../shell/pages/c/_cluster/_product/_resource/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/_resource/_id" */))
const _1ff7651d = () => interopDefault(import('../shell/pages/c/_cluster/_product/_resource/_namespace/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/_resource/_namespace/_id" */))
const _38b41bc5 = () => interopDefault(import('../shell/pages/index.vue' /* webpackChunkName: "shell/pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/dashboard/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _18fc4100,
    name: "about"
  }, {
    path: "/account",
    component: _531ccb63,
    name: "account"
  }, {
    path: "/c",
    component: _08c81659,
    name: "c"
  }, {
    path: "/clusters",
    component: _6b9d5e99,
    name: "clusters"
  }, {
    path: "/diagnostic",
    component: _35f50a44,
    name: "diagnostic"
  }, {
    path: "/fail-whale",
    component: _2edb9252,
    name: "fail-whale"
  }, {
    path: "/home",
    component: _38eb0f3c,
    name: "home"
  }, {
    path: "/prefs",
    component: _1c6c76a3,
    name: "prefs"
  }, {
    path: "/safeMode",
    component: _29446e2d,
    name: "safeMode"
  }, {
    path: "/support",
    component: _55f2c2f6,
    name: "support"
  }, {
    path: "/account/create-key",
    component: _5ba84766,
    name: "account-create-key"
  }, {
    path: "/auth/login",
    component: _87cdc542,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _141b9704,
    name: "auth-logout"
  }, {
    path: "/auth/setup",
    component: _7f73be73,
    name: "auth-setup"
  }, {
    path: "/auth/verify",
    component: _177a0b1a,
    name: "auth-verify"
  }, {
    path: "/docs/toc",
    component: _635af89e,
    name: "docs-toc"
  }, {
    path: "/rio/mesh",
    component: _1dc831e1,
    name: "rio-mesh"
  }, {
    path: "/c/:cluster",
    component: _1fd989f2,
    name: "c-cluster"
  }, {
    path: "/docs/:doc?",
    component: _446be7c0,
    name: "docs-doc"
  }, {
    path: "/c/:cluster/apps",
    component: _1868e6e0,
    name: "c-cluster-apps"
  }, {
    path: "/c/:cluster/auth",
    component: _554d9446,
    name: "c-cluster-auth"
  }, {
    path: "/c/:cluster/backup",
    component: _915c01c0,
    name: "c-cluster-backup"
  }, {
    path: "/c/:cluster/cis",
    component: _4ea7e476,
    name: "c-cluster-cis"
  }, {
    path: "/c/:cluster/ecm",
    component: _0a2c0a07,
    name: "c-cluster-ecm"
  }, {
    path: "/c/:cluster/explorer",
    component: _c52cf6c6,
    name: "c-cluster-explorer"
  }, {
    path: "/c/:cluster/fleet",
    component: _9594ec74,
    name: "c-cluster-fleet"
  }, {
    path: "/c/:cluster/gatekeeper",
    component: _a46914ca,
    name: "c-cluster-gatekeeper"
  }, {
    path: "/c/:cluster/istio",
    component: _1fd4c528,
    name: "c-cluster-istio"
  }, {
    path: "/c/:cluster/legacy",
    component: _53503f32,
    name: "c-cluster-legacy"
  }, {
    path: "/c/:cluster/logging",
    component: _63727357,
    name: "c-cluster-logging"
  }, {
    path: "/c/:cluster/longhorn",
    component: _8a4bcb46,
    name: "c-cluster-longhorn"
  }, {
    path: "/c/:cluster/manager",
    component: _6903a3b6,
    name: "c-cluster-manager"
  }, {
    path: "/c/:cluster/mcapps",
    component: _cd527234,
    name: "c-cluster-mcapps"
  }, {
    path: "/c/:cluster/monitoring",
    component: _44f024a6,
    name: "c-cluster-monitoring"
  }, {
    path: "/c/:cluster/neuvector",
    component: _9b6e92ce,
    name: "c-cluster-neuvector"
  }, {
    path: "/c/:cluster/settings",
    component: _54f45b81,
    name: "c-cluster-settings"
  }, {
    path: "/c/:cluster/uiplugins",
    component: _73f47b04,
    name: "c-cluster-uiplugins"
  }, {
    path: "/c/:cluster/apps/charts",
    component: _3ab5644a,
    name: "c-cluster-apps-charts"
  }, {
    path: "/c/:cluster/auth/config",
    component: _15f082fe,
    name: "c-cluster-auth-config"
  }, {
    path: "/c/:cluster/auth/roles",
    component: _ba76eb18,
    name: "c-cluster-auth-roles"
  }, {
    path: "/c/:cluster/explorer/ConfigBadge",
    component: _267d536c,
    name: "c-cluster-explorer-ConfigBadge"
  }, {
    path: "/c/:cluster/explorer/EventsTable",
    component: _71f1abc0,
    name: "c-cluster-explorer-EventsTable"
  }, {
    path: "/c/:cluster/explorer/explorer-utils",
    component: _1dbabeb2,
    name: "c-cluster-explorer-explorer-utils"
  }, {
    path: "/c/:cluster/explorer/tools",
    component: _9cf3e9ae,
    name: "c-cluster-explorer-tools"
  }, {
    path: "/c/:cluster/fleet/GitRepoGraphConfig",
    component: _075ece87,
    name: "c-cluster-fleet-GitRepoGraphConfig"
  }, {
    path: "/c/:cluster/gatekeeper/constraints",
    component: _2318d382,
    name: "c-cluster-gatekeeper-constraints"
  }, {
    path: "/c/:cluster/legacy/project",
    component: _57ac57f1,
    name: "c-cluster-legacy-project"
  }, {
    path: "/c/:cluster/manager/cloudCredential",
    component: _244eaa42,
    name: "c-cluster-manager-cloudCredential"
  }, {
    path: "/c/:cluster/monitoring/alertmanagerconfig",
    component: _74c080f2,
    name: "c-cluster-monitoring-alertmanagerconfig"
  }, {
    path: "/c/:cluster/monitoring/monitor",
    component: _9b397b9e,
    name: "c-cluster-monitoring-monitor"
  }, {
    path: "/c/:cluster/monitoring/route-receiver",
    component: _005e8e92,
    name: "c-cluster-monitoring-route-receiver"
  }, {
    path: "/c/:cluster/settings/banners",
    component: _678fad36,
    name: "c-cluster-settings-banners"
  }, {
    path: "/c/:cluster/settings/brand",
    component: _56291bb6,
    name: "c-cluster-settings-brand"
  }, {
    path: "/c/:cluster/settings/DefaultLinksEditor",
    component: _00c29606,
    name: "c-cluster-settings-DefaultLinksEditor"
  }, {
    path: "/c/:cluster/settings/links",
    component: _2550b6e8,
    name: "c-cluster-settings-links"
  }, {
    path: "/c/:cluster/settings/performance",
    component: _7b8412c2,
    name: "c-cluster-settings-performance"
  }, {
    path: "/c/:cluster/uiplugins/DeveloperInstallDialog",
    component: _3874b7f7,
    name: "c-cluster-uiplugins-DeveloperInstallDialog"
  }, {
    path: "/c/:cluster/uiplugins/InstallDialog",
    component: _7aed2655,
    name: "c-cluster-uiplugins-InstallDialog"
  }, {
    path: "/c/:cluster/uiplugins/PluginInfoPanel",
    component: _78822d15,
    name: "c-cluster-uiplugins-PluginInfoPanel"
  }, {
    path: "/c/:cluster/uiplugins/RemoveUIPlugins",
    component: _27cff8fa,
    name: "c-cluster-uiplugins-RemoveUIPlugins"
  }, {
    path: "/c/:cluster/uiplugins/SetupUIPlugins",
    component: _22e38726,
    name: "c-cluster-uiplugins-SetupUIPlugins"
  }, {
    path: "/c/:cluster/uiplugins/UninstallDialog",
    component: _1a5d465c,
    name: "c-cluster-uiplugins-UninstallDialog"
  }, {
    path: "/c/:cluster/apps/charts/chart",
    component: _6dd0a554,
    name: "c-cluster-apps-charts-chart"
  }, {
    path: "/c/:cluster/apps/charts/install",
    component: _5cdc46da,
    name: "c-cluster-apps-charts-install"
  }, {
    path: "/c/:cluster/apps/charts/install.helpers",
    component: _9ed144b6,
    name: "c-cluster-apps-charts-install.helpers"
  }, {
    path: "/c/:cluster/auth/group.principal/assign-edit",
    component: _54f2d50c,
    name: "c-cluster-auth-group.principal-assign-edit"
  }, {
    path: "/c/:cluster/legacy/project/pipelines",
    component: _0ecf0630,
    name: "c-cluster-legacy-project-pipelines"
  }, {
    path: "/c/:cluster/manager/cloudCredential/create",
    component: _e58a2e88,
    name: "c-cluster-manager-cloudCredential-create"
  }, {
    path: "/c/:cluster/monitoring/monitor/create",
    component: _7c8d62a6,
    name: "c-cluster-monitoring-monitor-create"
  }, {
    path: "/c/:cluster/monitoring/route-receiver/create",
    component: _33278e6c,
    name: "c-cluster-monitoring-route-receiver-create"
  }, {
    path: "/c/:cluster/explorer/tools/pages/:page?",
    component: _a265580c,
    name: "c-cluster-explorer-tools-pages-page"
  }, {
    path: "/c/:cluster/auth/config/:id",
    component: _3e7525ae,
    name: "c-cluster-auth-config-id"
  }, {
    path: "/c/:cluster/legacy/pages/:page?",
    component: _dc247a90,
    name: "c-cluster-legacy-pages-page"
  }, {
    path: "/c/:cluster/legacy/project/:page",
    component: _7f24c6e6,
    name: "c-cluster-legacy-project-page"
  }, {
    path: "/c/:cluster/manager/cloudCredential/:id",
    component: _f6b355ac,
    name: "c-cluster-manager-cloudCredential-id"
  }, {
    path: "/c/:cluster/manager/pages/:page?",
    component: _154649f6,
    name: "c-cluster-manager-pages-page"
  }, {
    path: "/c/:cluster/mcapps/pages/:page?",
    component: _9440e412,
    name: "c-cluster-mcapps-pages-page"
  }, {
    path: "/c/:cluster/monitoring/alertmanagerconfig/:alertmanagerconfigid",
    component: _f6c2a820,
    name: "c-cluster-monitoring-alertmanagerconfig-alertmanagerconfigid"
  }, {
    path: "/c/:cluster/monitoring/route-receiver/:id?",
    component: _05030d7a,
    name: "c-cluster-monitoring-route-receiver-id"
  }, {
    path: "/c/:cluster/auth/roles/:resource/create",
    component: _a9b0ada8,
    name: "c-cluster-auth-roles-resource-create"
  }, {
    path: "/c/:cluster/monitoring/alertmanagerconfig/:alertmanagerconfigid/receiver",
    component: _41fe647e,
    name: "c-cluster-monitoring-alertmanagerconfig-alertmanagerconfigid-receiver"
  }, {
    path: "/c/:cluster/auth/roles/:resource/:id?",
    component: _70b6eaba,
    name: "c-cluster-auth-roles-resource-id"
  }, {
    path: "/c/:cluster/monitoring/monitor/:namespace/:id?",
    component: _33745838,
    name: "c-cluster-monitoring-monitor-namespace-id"
  }, {
    path: "/c/:cluster/navlinks/:group?",
    component: _b27ea8a4,
    name: "c-cluster-navlinks-group"
  }, {
    path: "/c/:cluster/:product",
    component: _7eccc6ce,
    name: "c-cluster-product"
  }, {
    path: "/c/:cluster/:product/members",
    component: _24dce558,
    name: "c-cluster-product-members"
  }, {
    path: "/c/:cluster/:product/namespaces",
    component: _22acf1a8,
    name: "c-cluster-product-namespaces"
  }, {
    path: "/c/:cluster/:product/projectsnamespaces",
    component: _294b6626,
    name: "c-cluster-product-projectsnamespaces"
  }, {
    path: "/c/:cluster/:product/:resource",
    component: _9a37a0a8,
    name: "c-cluster-product-resource"
  }, {
    path: "/c/:cluster/:product/:resource/create",
    component: _5d53dedc,
    name: "c-cluster-product-resource-create"
  }, {
    path: "/c/:cluster/:product/:resource/:id",
    component: _225ba5d8,
    name: "c-cluster-product-resource-id"
  }, {
    path: "/c/:cluster/:product/:resource/:namespace/:id?",
    component: _1ff7651d,
    name: "c-cluster-product-resource-namespace-id"
  }, {
    path: "/",
    component: _38b41bc5,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
