import { render, staticRenderFns } from "./management.cattle.io.project.vue?vue&type=template&id=1511ba59&scoped=true&"
import script from "./management.cattle.io.project.vue?vue&type=script&lang=js&"
export * from "./management.cattle.io.project.vue?vue&type=script&lang=js&"
import style0 from "./management.cattle.io.project.vue?vue&type=style&index=0&id=1511ba59&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1511ba59",
  null
  
)

export default component.exports