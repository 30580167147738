import { render, staticRenderFns } from "./syslog.vue?vue&type=template&id=b752f374&scoped=true&"
import script from "./syslog.vue?vue&type=script&lang=js&"
export * from "./syslog.vue?vue&type=script&lang=js&"
import style0 from "./syslog.vue?vue&type=style&index=0&id=b752f374&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b752f374",
  null
  
)

export default component.exports