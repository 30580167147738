import { render, staticRenderFns } from "./management.cattle.io.setting.vue?vue&type=template&id=17f7fd8d&scoped=true&"
import script from "./management.cattle.io.setting.vue?vue&type=script&lang=js&"
export * from "./management.cattle.io.setting.vue?vue&type=script&lang=js&"
import style0 from "./management.cattle.io.setting.vue?vue&type=style&index=0&id=17f7fd8d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17f7fd8d",
  null
  
)

export default component.exports